<template>
  <div>
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      style="width: 100%"
      tooltip-effect="light"
    >
      <el-table-column
        prop="realName"
        align="left"
        width="220"
        :show-overflow-tooltip="true"
        label="成员姓名"
      >
      </el-table-column>
      <el-table-column
        prop="teamList"
        align="left"
        width="220"
        :show-overflow-tooltip="true"
        label="所属团队"
      >
        <template slot-scope="scope">
          <span v-for="(item, index) in scope.row.teamList" :key="index"
            ><i v-if="index != '0'"> 、 </i>{{ item }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="pointCount"
        align="left"
        width="80"
        :show-overflow-tooltip="true"
        label="总分"
      >
      </el-table-column>
       <el-table-column
        prop="publicJobPublished"
        align="left"
        width="180"
        :show-overflow-tooltip="true"
        label="发布大厂机会职位"
      >
      </el-table-column>
      <el-table-column
        prop="updateMobilePoint"
        align="left"
        width="150"
        :show-overflow-tooltip="true"
        label="更新手机号码"
      >
      </el-table-column>
      <el-table-column
        prop="updateTitlePoint"
        align="left"
        width="150"
        :show-overflow-tooltip="true"
        label="更新当前职位"
      >
      </el-table-column>
      <el-table-column
        prop="updateCompanyPoint"
        align="left"
        width="150"
        :show-overflow-tooltip="true"
        label="更新当前雇主"
      >
      </el-table-column>
      <el-table-column
        prop="createNewCityExpectationsPoint"
        align="left"
        width="180"
        :show-overflow-tooltip="true"
        label="首次填写新一线机会"
      >
      </el-table-column>
      <el-table-column
        prop="uploadHighQualityCandidatePoint"
        align="left"
        width="150"
        :show-overflow-tooltip="true"
        label="上传高净值简历"
      >
      </el-table-column>
      <el-table-column
        prop="markMobileInvalidPoint"
        align="left"
        width="180"
        :show-overflow-tooltip="true"
        label="标记手机号码失效"
      >
      </el-table-column>
      <el-table-column
        prop="jdtRegisterPoint"
        align="left"
        width="210"
        :show-overflow-tooltip="true"
        label="Csdn职通车推荐注册成功"
      >
      </el-table-column>
      <el-table-column
        prop="jdtShareJobPoint"
        align="left"
        width="180"
        :show-overflow-tooltip="true"
        label="Csdn职通车分享职位"
      >
      </el-table-column>
      <el-table-column
        prop="adminOperatePoint"
        align="left"
        width="150"
        :show-overflow-tooltip="true"
        label="管理员增减积分"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "team-points-table",
  props: {
    tableData: Array,
    loading: Boolean,
  },
  components: {
  },
  data() {
    return {};
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="scss" scope>
// .el-table--border .el-table__cell {
//   border: none;
// }
// .el-table th.el-table__cell.is-leaf,
// .el-table td.el-table__cell {
//   border: none;
// }
// .el-table thead th.el-table__cell {
//   background: #f8f8f8;
// }
// // .el-table thead tr:first-child th {
// //   text-align: center;
// // }
// .el-table__empty-block {
//   width: 100% !important;
// }

.ell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.num {
  color: #38bc9d;
  cursor: pointer;
}

.el-table {
  thead.has-gutter tr th {
    background-color: #F0F4F7;
    .cell {
      color: #666;
      font-weight: bold;
    }
  }
  td .cell {
    padding: 0 20px;
  }
}
</style>