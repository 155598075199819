<template>
  <div class="team-points">
    <div class="team-points-content">
      <div class="team-points-title">
        <div class="table-title">团队积分</div>
        <div class="team-points-top">
          <!-- 时间段选择 -->
          <div
            class="date-wrap triangle-drop"
            :class="isFocusDate ? 'triangle-drop-up' : 'triangle-drop-down'"
          >
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              start-placeholder="开始日期"
              range-separator="-"
              end-placeholder="结束日期"
              size="small"
              :editable="false"
              align="center"
              format="yyyyMMdd"
              @change="handleDate"
              @focus="handleFocusDate"
              @blur="handleBlurDate"
              :clearable="false"
              :picker-options="expireTimeOption"
              popper-class="performance-data-range"
            >
            </el-date-picker>
          </div>
          <!-- 成员选择 -->
          <div
            class="employee-select-wrap triangle-drop"
            :class="
              teamMemberPopoverVisible ? 'triangle-drop-up' : 'triangle-drop-down'
            "
          >
            <el-popover
              popper-class="team-member-popover team-points-team-member-popover"
              placement="bottom-end"
              width="700"
              trigger="click"
              v-model="teamMemberPopoverVisible"
            >
              <team-member-select
                ref="teamSelect"
                :isAllMembers="true"
                :isShowDimission="false"
                :date="dateRange"
                :isHistoryData="true"
                :visible="teamMemberPopoverVisible"
                :selected="userIds"
                @item-select-cb="employeeSelect"
              ></team-member-select>
              <div slot="reference" class="employee-select-text">
                {{ selectedMembersText }}
              </div>
            </el-popover>
          </div>

          <el-button
            class="export"
            type="primary"
            :loading="exportLoading"
            @click="handleExport"
            >导出明细</el-button
          >
        </div>
      </div>

      <div class="team-points-table">
        <team-points-table
          :loading="loading"
          :tableData="tableData"
          :extraData="extraData"
          :type="teamPointsType"
        >
        </team-points-table>

        <!-- 分页 -->
        <div class="work-table-footer">
          <el-pagination
            class="el-pagination-workTable"
            layout="total, sizes, prev, pager, next, slot"
            :current-page="page"
            :page-sizes="[15, 20, 50]"
            :page-size="pageSize"
            :total="totalCount"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
            <span class="pagination-text">
              <span
                >前往<el-input
                  v-model="pagerJump"
                  @keyup.enter.native="handlePagerJump"
                ></el-input
                >页</span
              >
              <span @click="handlePagerJump">跳转</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  deliveryTarget as deliveryTargetUrl,
  teamPoints as teamPointsUrl,
} from "#/js/config/javaApi.json";
import TeamMemberSelect from "#/component/common/team-member-select.vue";
import TeamPointsTable from "./layout/teamPointsTable.vue";
import { downloadFile } from "#/js/util/fileUtil.js";

export default {
  name: "TeamPoints",
  components: {
    TeamMemberSelect,
    TeamPointsTable,
  },
  data() {
    let _minTime = null,
      _maxTime = new Date().getTime();
    return {
      loading: false,
      teamMemberPopoverVisible: false,
      selectedMembersText: "全部成员",
      dateFormat: "yyyy-MM",
      totalCount: 0,
      page: 1,
      pageSize: 15,
      pagerJump: 0,
      startDate: "",
      endDate: "",
      userIds: [],
      teamPointsType: 0,
      tableData: [],
      extraData: [],
      dateRange: [],
      isFocusDate: false, // 用于控制日期的三角
      expireTimeOption: {
        onPick: (time) => {
          // 如果选择了只选择了一个时间
          if (!time.maxDate) {
            let _nDays = this.key == "trackingListDetails" ? 31 : 365;
            let timeRange = _nDays * 24 * 60 * 60 * 1000, // *天
              _dNow = new Date().getTime();

            _minTime = time.minDate.getTime() - timeRange; // 最小时间
            _maxTime = time.minDate.getTime() + timeRange; // 最大时间
            _maxTime = moment(_maxTime).isSameOrAfter(_dNow) ? _dNow : _maxTime;
            // 如果选了两个时间，那就清空本次范围判断数据，以备重选
          } else {
            _minTime = _maxTime = null;
          }
        },
        disabledDate: (time) => {
          // onPick后触发
          // 该方法会轮询当3个月内的每一个日期，返回false表示该日期禁选
          if (_minTime && _maxTime) {
            return time.getTime() < _minTime || time.getTime() > _maxTime;
          }
          if (!_minTime && _maxTime) {
            return time.getTime() > _maxTime;
          }
        },
        shortcuts: [
          {
            text: "昨天",
            onClick(picker) {
              const end = moment().subtract(1, "days").format("YYYY-MM-DD");
              const start = moment().subtract(1, "days").format("YYYY-MM-DD");
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "今天",
            onClick(picker) {
              const end = moment().subtract(0, "days").format("YYYY-MM-DD");
              const start = moment().subtract(0, "days").format("YYYY-MM-DD");
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近7天",
            onClick(picker) {
              const end = moment().subtract(0, "days").format("YYYY-MM-DD");
              const start = moment().subtract(6, "days").format("YYYY-MM-DD");
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近30天",
            onClick(picker) {
              const end = moment().subtract(0, "days").format("YYYY-MM-DD");
              const start = moment().subtract(29, "days").format("YYYY-MM-DD");
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: '本周',
            onClick(picker) {
              const start = moment().weekday(0).format('YYYY-MM-DD');
              const end = moment().weekday(6).format('YYYY-MM-DD');
              picker.$emit('pick', [start, end]);
            }
          }, 
          {
            text: "本月",
            onClick(picker) {
              const start = moment().add("month", 0).format("YYYY-MM") + "-01";
              const end = moment(start)
                .add("month", 1)
                .add("days", -1)
                .format("YYYY-MM-DD");
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "上月",
            onClick(picker) {
              const start =
                moment().subtract(1, "month").format("YYYY-MM") + "-01";
              const end = moment(start)
                .subtract(-1, "month")
                .add("days", -1)
                .format("YYYY-MM-DD");
              picker.$emit("pick", [start, end]);
            },
          },
        ],
        // ,{
        //     text: '今年',
        //     onClick(picker) {
        //         const start = moment().year() + '-01-01';
        //         const end = moment().format('YYYY-MM-DD');
        //         picker.$emit('pick', [start, end]);
        //     }
        // }
      },
      exportLoading: false,
    };
  },
  computed: {
    // dateRange() {
    //   if (this.startDate && this.endDate) {
    //     return [this.startDate, this.endDate];
    //   } else {
    //     let startDate =
    //       moment().subtract(0, "months").format("YYYY-MM") + "-01";
    //     let endDate = moment(startDate)
    //       .add(1, "month")
    //       .subtract(1, "days")
    //       .format("YYYY-MM-DD");
    //     return [startDate, endDate];
    //   }
    // },
  },
  filters: {
    numPointFilter(val) {
      return val.replace(".00", "");
    },
    dataFilter(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:SS");
    },
  },
  created() {},
  mounted() {
    // if (!this.$store.state.user.userInfo.isAdministrator && !this.$store.state.user.userInfo.isAdminOrTeamLeader) {
    if ((!this.$store.state.user.userInfo.isAdministrator && !this.$store.state.user.userInfo.isAdminOrTeamLeader) || !this.$store.state.user.userInfo.isCFUser) {
      shortTips("权限不足，请联系管理员");
      setTimeout(() => {
        location.href = "/Headhunting/#/";
      }, 2000);
    } else {
      if (sessionStorage.getItem("teamPointsData")) {
      } else if (localStorage.getItem("perpormancesDetailData")) {
        let jsonStr = localStorage.getItem("perpormancesDetailData");
        sessionStorage.setItem("teamPointsData", jsonStr);
      }
      let json = JSON.parse(sessionStorage.getItem("teamPointsData"));
      this.teamPointsType = parseInt(json.teamPointsType || 0);

      this.startDate = moment().subtract(0, "months").format("YYYY-MM") + "-01";
      this.endDate = moment(this.startDate)
        .add(1, "month")
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      this.dateRange = [this.startDate, this.endDate];
      this.loading = true;
      this.$refs.teamSelect.getHistoryTeamData(true);
    }
  },
  methods: {
    // 分页
    handleSizeChange(val) {
      this.page = 1;
      this.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    handlePagerJump() {
      let currentPager = Number(this.pagerJump),
        currentPageTotal = Math.ceil(this.totalCount / this.pageSize);
      if (currentPager > 0 && currentPager <= currentPageTotal) {
        this.page = currentPager;
        this.getData();
      }
    },
    // 选择时间
    handleDate(value) {
      // console.log(value);
      this.dateFormat = "yyyy-MM-dd";
      this.startDate = value[0];
      this.endDate = value[1];
      this.page = 1;
      this.loading = true;
      this.$refs.teamSelect.getHistoryTeamData(true);
    },
    handleFocusDate() {
      this.isFocusDate = true;
    },
    handleBlurDate() {
      this.isFocusDate = false;
    },
    // 选择成员
    employeeSelect(userIds, text) {
      this.userIds = userIds;
      this.selectedMembersText = text;
      this.page = 1;
      this.getData();
      this.teamMemberPopoverVisible = false;
    },
    // 获取团队积分列表数据
    getData() {
      this.loading = true;
      let data = {
        achievementDetailType: 0,
        startDate: this.startDate,
        endDate: this.endDate,
        offset: (this.page - 1) * this.pageSize,
        pageSize: this.pageSize,
        userIds: this.userIds,
      };
      _request({
        method: "POST",
        url: teamPointsUrl.user_point_detail,
        baseURL: "LbdJavaApi",
        javaProject: "performance",
        data: data,
      }).then((res) => {
        // console.log(res);
        this.totalCount = res.count;
        this.tableData = res.data;
        this.loading = false;

        this.$nextTick(() => {
          // 处理跳转页面后重新滚动到顶部,兼容200内滚动不自动滑动到顶部
          // const _scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
          const _scrollTop = document.querySelector(".team-points").scrollTop;
          if (_scrollTop > 200) {
            // document.documentElement.scrollTop = 0;
            // document.body.scrollTop = 0;
            // console.log("scroll top");
            document.querySelector(".team-points").scrollTop = 0;
          }
        });
      });
    },

    // 导出
    handleExport() {
      this.exportLoading = true;
      let params = {
        achievementDetailType: 0,
        customerIds: null,
        startDate: this.startDate,
        endDate: this.endDate,
        offset: (this.page - 1) * this.pageSize,
        pageSize: this.pageSize,
        userIds: this.userIds,
      };
      _request({
        method: "POST",
        baseURL: "LbdJavaApi",
        javaProject: "performance",
        url: teamPointsUrl.user_point_export,
        responseType: "blob",
        data: params,
      })
        .then((res) => {
          downloadFile(res);
        })
        .catch((err) => {
          shortTips(err.message || "系统异常");
        })
        .finally(() => {
          this.exportLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scope>
.team-points {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
  &-content {
    padding-bottom: 38px;
    background-color: #fff;
    border-radius: 8px;
    min-height: calc(100vh - 90px);
  }

  &-title {
    display: flex;
    justify-content: space-between;
    line-height: 32px;
    padding: 30px 30px 0;
    margin-bottom: 8px;

    .table-title {
      font-size: 28px;
      color: #666;
      line-height: 32px;
    }
  }

  .el-table {
    thead tr th {
      background-color: #F0F4F7;
      .cell {
        color: #666;
        font-weight: bold;
        padding: 0 20px;
      }
    }
    td .cell {
      padding: 0 20px;
    }
  }

  .el-table th, .el-table td {
    padding: 8px 0;
  }

  &-top {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    position: relative;
    text-align: center;
    .el-select.select-client {
      &:hover {
        .el-input__inner {
          background: #38bc9d;
          border-color: #38bc9d;
          color: #fff;
          &::placeholder {
            color: #fff;
          }
        }
      }
      input.el-input__inner {
        width: 118px;
        height: 32px;
        // border-radius: 16px;
        // &:hover {
        //     background-color: #38bc9c;
        // }
      }
      .el-icon-arrow-up:before {
        display: none;
      }
    }
    .export {
      // position: absolute;
      // right: 0;
      margin-left: 20px;
      border-radius: 4px;
    }
  }

  .date-wrap {
    position: relative;
    cursor: pointer;
    // width: 200px;
    width: 260px;
    &:hover {
      .el-date-editor--daterange.el-input__inner {
        border-color: #38bc9d;
        background: #38bc9d;
        .el-range-input {
          background: #38bc9d;
          color: #fff;
        }
        .el-range-separator {
          color: #fff;
        }
      }
      /deep/ .el-input__inner {
        &::placeholder {
          color: #fff;
        }
      }
    }

    .el-date-editor {
      height: 32px;
      // width: 200px;
      width: 260px;
      // border-radius: 16px;
      .el-input__inner {
        // border-radius: 16px;
        padding-left: 20px;
        padding-right: 20px;
        cursor: pointer;
      }
      .el-range__icon {
        // display: none;
      }
      .el-range__close-icon {
        display: none;
      }
      .el-input__prefix,
      .el-input__suffix {
        display: none;
      }
    }
  }
  .triangle-drop {
    &:after {
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
      pointer-events: none;
    }
    &-down {
      &:after {
        border-top: 12px solid #ddd;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
      }
      &:hover {
        &:after {
          border-top: 12px solid #fff;
        }
      }
    }
    &-up {
      &:after {
        border-bottom: 12px solid #ddd;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
      }
      &:hover {
        &:after {
          border-bottom: 12px solid #fff;
        }
      }
    }
  }

  .employee-select-wrap {
    position: relative;
    margin-left: 20px;
    .employee-select-text {
      display: inline-block;
      width: 116px;
      height: 32px;
      line-height: 32px;
      border: 1px solid #d3d3d3;
      // border-radius: 16px;
      padding: 0 36px 0 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: top;
    }
    &:hover {
      .employee-select-text {
        background: #38bc9d;
        border-color: #38bc9d;
        color: #fff;
      }
    }
    &.triangle-drop-up {
      &::after {
        border-bottom: 12px solid #fff;
        margin-top: -3px;
      }
      .employee-select-text {
        background: #38bc9d;
        border-color: #38bc9d;
        color: #fff;
      }
    }
  }

  .work-table-footer {
    margin: 35px 35px 0 0;
    overflow: hidden;
  }
}
</style>
<style lang="scss">
.team-points {
  /deep/ .el-input__inner {
    &::placeholder {
      color: #606266;
    }
  }
}

.team-member-popover.team-points-team-member-popover {
  transform: translateX(0);
}
.layout-transition {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.performance-data-range.el-date-range-picker {
  .popper__arrow {
    left: 50% !important;
  }
}
.offer-edit-log-popper.el-popper {
  padding: 0;
  .offer-edit-log-list {
    max-height: 560px;
    overflow: hidden;
    overflow-y: auto;
    padding: 20px;
    .offer-edit-log-item {
      p {
        color: #999;
        line-height: 20px;
        margin-bottom: 0;
      }
      .offer-info-time {
        margin-bottom: 4px;
        color: #666;
      }
      .offer-log-title {
        color: #333;
        line-height: 20px;
        .offer-log-updator {
          color: $primary;
          margin-right: 5px;
        }
      }
      & + .offer-edit-log-item {
        margin-top: 20px;
      }
    }
  }
}

.date-type-menu.el-dropdown-menu {
  width: 70px;
  margin-top: 6px;
  padding: 0;
  .el-dropdown-menu__item {
    line-height: 40px;
    font-size: 14px;
    width: 70px;
    text-align: center;
    &.is-active {
      background-color: #ebf8f5;
      color: #60c9b1;
    }
  }
}
</style>