var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "", "tooltip-effect": "light" },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "realName",
              align: "left",
              width: "220",
              "show-overflow-tooltip": true,
              label: "成员姓名",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "teamList",
              align: "left",
              width: "220",
              "show-overflow-tooltip": true,
              label: "所属团队",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.teamList, function (item, index) {
                    return _c("span", { key: index }, [
                      index != "0" ? _c("i", [_vm._v(" 、 ")]) : _vm._e(),
                      _vm._v(_vm._s(item)),
                    ])
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "pointCount",
              align: "left",
              width: "80",
              "show-overflow-tooltip": true,
              label: "总分",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "publicJobPublished",
              align: "left",
              width: "180",
              "show-overflow-tooltip": true,
              label: "发布大厂机会职位",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateMobilePoint",
              align: "left",
              width: "150",
              "show-overflow-tooltip": true,
              label: "更新手机号码",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateTitlePoint",
              align: "left",
              width: "150",
              "show-overflow-tooltip": true,
              label: "更新当前职位",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateCompanyPoint",
              align: "left",
              width: "150",
              "show-overflow-tooltip": true,
              label: "更新当前雇主",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createNewCityExpectationsPoint",
              align: "left",
              width: "180",
              "show-overflow-tooltip": true,
              label: "首次填写新一线机会",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "uploadHighQualityCandidatePoint",
              align: "left",
              width: "150",
              "show-overflow-tooltip": true,
              label: "上传高净值简历",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "markMobileInvalidPoint",
              align: "left",
              width: "180",
              "show-overflow-tooltip": true,
              label: "标记手机号码失效",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "jdtRegisterPoint",
              align: "left",
              width: "210",
              "show-overflow-tooltip": true,
              label: "Csdn职通车推荐注册成功",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "jdtShareJobPoint",
              align: "left",
              width: "180",
              "show-overflow-tooltip": true,
              label: "Csdn职通车分享职位",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "adminOperatePoint",
              align: "left",
              width: "150",
              "show-overflow-tooltip": true,
              label: "管理员增减积分",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }